import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Ted Talks for Veterans in Transition: talk nerdy to me",
  "author": "Purepost",
  "date": "2018-03-28T05:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "ted-talks-for-veterans-in-transition-talk-nerdy-to-me",
  "draft": false,
  "meta_title": "Ted Talks for Veterans in Transition: talk nerdy to me"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`There is an art in being able to explain yourself. Telling your story in a way that makes it compelling to an employer or interviewer is a skill to be mastered. Explaining your personal contribution and relevance to others doesn't necessarily involve comprising your background and content but it does involve some self-awareness and delivery.`}</p>
    <p>{`How can you effectively talk and communicate your way through an interview process?`}<a parentName="p" {...{
        "href": "http://www.presentyourscience.com/"
      }}>{` Melissa Marshall`}</a>{` has some great tips... gone are the days of death by PowerPoint.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      